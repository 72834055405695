import { useEffect } from "react";

export function Home() {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = "https://www.instagram.com/designstudioporch/"; // ここにジャンプしたいURLを指定
    }, 1000);

    return () => clearTimeout(timer); // コンポーネントがアンマウントされたらタイマーをクリア
  }, []);

  return (
    <>
      <a href="https://www.instagram.com/designstudioporch/">
        Instagramへ移動します。
      </a>
    </>
  );
}
